import IH1B from "../../interfaces/IH1B";

export async function fetchH1bData(year: number, h1bFormData: IH1B) {
  //   const apiUrl = `https://visadatabase.azurewebsites.net/api/H1bData/GetH1bDataModelByFiscalYear/${year}`;


   


  function objectToQueryString(obj: any) {
    const queryParams = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        );
      }
    }
    return queryParams.join("&");
  }

  const queryParams = objectToQueryString(h1bFormData as IH1B);

  const apiUrl = `https://visadatabase.azurewebsites.net/api/H1bData?${queryParams}`;


  // Send the form data to the server securely
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      // Form submitted successfully
 
      const serverResponse = (await response.json()) as IH1B[];
       return serverResponse;
    } else {
      // Handle server errors
      console.error("Server error");
      return false;
    }
  } catch (error) {
    // Handle network errors
    console.error("Network error", error);
  }
}
