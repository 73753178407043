import IH1B from "../../interfaces/IH1B";
import ILoading from "../../interfaces/ILoading";

export enum h1bDispatchActions {
  updateH1Bdata = "updateH1Bdata",
  API_ERROR = "API_ERROR",
}

export enum loadingDispatchActions {
  startSpinner = "startSpinner",
}

export type h1bDispatchAction =
  | {
      type: h1bDispatchActions.updateH1Bdata;
      h1bData: IH1B;
    }
  | {
      type: h1bDispatchActions.API_ERROR;
      API_ERROR: { error: "API ERROR" };
    };


    export type loadingDispatchAction =
   {
      type: loadingDispatchActions.startSpinner;
      loading: ILoading;
    }
 