import { ReactNode, createContext, useContext, useReducer } from "react";
import { IStateContext } from "../../interfaces/IStateContext";
import IH1B from "../../interfaces/IH1B";
import { h1bDispatchAction, loadingDispatchAction } from "./DispatchActions";
import h1bReducer from "../reducers/h1bReducer";
import ILoading from "../../interfaces/ILoading";
import loadingReducer from "../reducers/loadingReducer";

const StateContext = createContext<IStateContext>({
  h1bData: [] as IH1B[],
  h1bDataDispatch: (action: h1bDispatchAction) => [],


  loading: {startSpinner: false},
  loadingDispatch: (action: loadingDispatchAction) => {}
});

export const StateDataProvider = ({ children }: { children?: ReactNode }) => {
  const [h1bData, h1bDataDispatch] = useReducer(h1bReducer, {} as IH1B);

  const [loading, loadingDispatch] = useReducer(loadingReducer, false);

  const value = {
    h1bData,
    h1bDataDispatch,
    loading,
    loadingDispatch,
  };

  return (
    <StateContext.Provider value={value}> {children} </StateContext.Provider>
  );
};

export function useAppState() {
  const context = useContext(StateContext);
  return context;
}
