export default function loadingReducer(state: any, action: any) {
  switch (action.type) {
    case "startSpinner": {
      return action.loading.startSpinner;
      break;
    }

    default:
      break;
  }
}
