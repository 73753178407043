import React, { useEffect, useState } from "react";
import styles from "./Search.module.scss";
import { fetchH1bData } from "../../../services/api/API";
import IH1B from "../../../interfaces/IH1B";
import { useAppState } from "../../../hooks/context/StateContext";
import {
  h1bDispatchActions,
  loadingDispatchActions,
} from "../../../hooks/context/DispatchActions";
import Spinner from "../../common/Spinner/Spinner";
import EmptyResult from "../EmptyResult/EmptyResult";

const Search = () => {
  const { h1bData, h1bDataDispatch, loading, loadingDispatch } = useAppState();

  const years = Array.from({ length: 24 }, (_, index) => 2000 + index);
  const stateAbbreviations = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  useEffect(() => {
    // Change the title when the component mounts
    document.title = "Visa Database";
  }, []);

  const [h1bFormData, seth1bFormData] = useState<IH1B>({
    employer: "",
    fiscalYear: NaN,
    state: "",
    city: "",
    zip: NaN,
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    // If the field is zipCode and the value is not empty, parse it to an integer
    const parsedValue =
      name === "zipCode" && value !== "" ? parseInt(value, 10) : value;

    seth1bFormData({ ...h1bFormData, [name]: parsedValue });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    loadingDispatch({
      type: loadingDispatchActions.startSpinner,
      loading: { startSpinner: true },
    });

    // Implement server-side validation and data processing here
    const isValid = await validateFormData(h1bFormData);

    if (isValid) {
      try {
        // Make the API call here
        const response = await fetchH1bData(
          h1bFormData.fiscalYear,
          h1bFormData
        );

        // If the API call is successful, you can dispatch an action with the API response
        h1bDataDispatch({
          type: h1bDispatchActions.updateH1Bdata,
          h1bData: response as unknown as IH1B,
        });

        loadingDispatch({
          type: loadingDispatchActions.startSpinner,
          loading: { startSpinner: false },
        });
      } catch (error: any) {
        // Handle API errors
        console.error("API Error:", error);
        // Dispatch an action for error handling if needed
        h1bDataDispatch({
          type: h1bDispatchActions.API_ERROR,
          API_ERROR: error.message,
        });
      }
    } else {
      window.alert("Error: Request Query is Invalid");
    }
  };

  const validateFormData = async (data: IH1B) => {
    // Implement server-side validation logic here
    // Return true if data is valid, false otherwise

    return true;
  };

  return (
    <div className={styles.searchWrapper}>
      <form>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0 mb-lg-0">
            <input
              id="employer"
              name="employer"
              type="text"
              className="form-control"
              placeholder="Employer Name"
              required
              onChange={handleChange}
            />
          </div>

          <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0 mb-lg-0">
            <input
              id="city"
              name="city"
              type="text"
              className="form-control"
              placeholder="City"
              onChange={handleChange}
            />
          </div>

          <div className="col-12 col-md-4 col-lg-2 mb-3 mb-md-0 mb-lg-0">
            <input
              type="text"
              id="zip"
              name="zip"
              className="form-control"
              placeholder="Zip Code"
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-12 col-md-4 col-lg-2 mb-3 mb-md-0 mb-lg-0">
            <select
              id="inputState1"
              className="form-control"
              onChange={handleChange}
              defaultValue={"CA"}
            >
              {stateAbbreviations.map((abbreviation) => (
                <option key={abbreviation} value={abbreviation}>
                  {abbreviation}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12 col-md-4 col-lg-2 mb-3 mb-md-0 mb-lg-0 dropdown">
            <select
              id="fiscalYear"
              name="fiscalYear"
              value={h1bFormData.fiscalYear}
              onChange={handleChange}
              required
              className="form-control"
            >
              <option
                defaultValue={h1bFormData.fiscalYear}
                data-bs-toggle="dropdown"
              >
                Select Fiscal Year
              </option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.searchButton}>
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: "150px" }}
              onClick={(e) => handleSubmit(e)}
            >
              Search
            </button>
          </div>
        </div>
      </form>

      {loading ? (
        <Spinner />
      ) : h1bData.length >= 1 ? (
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className={styles.stickyColmn}>#</th>
                <th scope="col">Company</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">Zip Code</th>
                <th scope="col">Fiscal Year</th>
              </tr>
            </thead>
            <tbody>
              {h1bData.map((result, index) => (
                <tr key={result.indexKey}>
                  <td className={styles.stickyColmn}> {index + 1}</td>
                  <td>{result.employer}</td>
                  <td>{result.city}</td>
                  <td>{result.state}</td>
                  <td>{result.zip}</td>
                  <td>{result.fiscalYear}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        h1bData.length === 0 && <EmptyResult />
      )}
    </div>
  );
};

export default Search;
