import React from "react";
import "./App.css";
import Header from "./components/app/Header/Header";
import Search from "./components/app/Search/Search";

function App() {
  return (
    <>
      <Header />
      <Search />
    </>
  );
}

export default App;
